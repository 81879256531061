import * as RichTextNodeTypes from './RichTextNodeTypes'

const Name = {
  [RichTextNodeTypes.listItem]: 'renderListItem',
  [RichTextNodeTypes.orderedList]: 'renderOrderedList',
  [RichTextNodeTypes.unorderedList]: 'renderUnorderedList',
  [RichTextNodeTypes.paragraph]: 'renderParagraph',
  [RichTextNodeTypes.blockquote]: 'renderBlockquote',
  [RichTextNodeTypes.hyperlink]: 'renderHyperlink',
  [RichTextNodeTypes.text]: 'renderText',
  [RichTextNodeTypes.hr]: 'renderHR',
  [RichTextNodeTypes.h1]: 'renderHeading',
  [RichTextNodeTypes.h2]: 'renderHeading',
  [RichTextNodeTypes.h3]: 'renderHeading',
  [RichTextNodeTypes.h4]: 'renderHeading',
  [RichTextNodeTypes.h5]: 'renderHeading',
  [RichTextNodeTypes.h6]: 'renderHeading',
};

export default Name