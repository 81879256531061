import React from 'react'
import smoothScrollTo, { getOffsetTopFromHash } from '../../providers/SmoothScrollProvider'

const targetLinkDetect = url => {
  const target = ['', '_blank']
  let tIndex = 0
  if (url.startsWith('//') || url.startsWith('http')) {
    const host = window.location.host
    const urlHost = url.split('//')[1]
    if (!(urlHost.startsWith(host) || urlHost.split('/')[0].includes(host))) {
      tIndex = 1
    }
  }
  return target[tIndex]
}

const handleClick = (url, callback) => {
  const target = targetLinkDetect(url)
  if (!target) {
    smoothScrollTo(getOffsetTopFromHash(url))
  }
  callback && callback()
}

const LinkComponentItem = ({ data, linkProp, className, renderChildOnly, onClickHandle, children }) => {
  if (!data) {
    return <></>
  }
  if (renderChildOnly) {
    return children(data.fields)
  }
  let url = (data.fields || {}).linkComponentItemLink || data[linkProp] || (data.fields || {})[linkProp]
  if (!url) {
    return <></>
  }

  // https://www.bpplus.com.au
  if( url.includes("https://www.bpplus.com.au") ){
    url = url.replace("https://www.bpplus.com.au","");
  }

  return (
    <a className={className} onClick={() => handleClick(url, onClickHandle)} href={url} target={targetLinkDetect(url)}>
      {children && children(data.fields)}
    </a>
  )
}

export default LinkComponentItem
