import axios from 'axios'
import * as ComponentTypes from '../constants/ComponentTypes'
import {
  FIXED_HOMEPAGE_ID
} from '../constants/Config'



let API_BASE_URL = require('../constants/Config').API_DATA_URL

let API_SPACE_ID = process.env.REACT_APP_BP_SPACE_ID
let API_TOKEN = process.env.REACT_APP_BP_ACCESS_TOKEN
let API_PREVIEW_TOKEN = process.env.REACT_APP_BP_PREVIEW_ACCESS_TOKEN

// const endpoint = API_BASE_URL.replace('{env}', 'cdn').replace('{space}', API_SPACE_ID).replace('{token}', API_TOKEN)
// const previewEndpoint = API_BASE_URL.replace('{env}', 'preview').replace('{space}', API_SPACE_ID).replace('{token}', API_PREVIEW_TOKEN)

const data = {
  isReady: false,
  data: null,
  debug: null
}

export default function getData(isPreviewURL = false) {
  let endpointV2 = API_BASE_URL.replace('{env}', 'cdn').replace('{space}', API_SPACE_ID).replace('{token}', API_TOKEN)
  let previewEndpointV2 = API_BASE_URL.replace('{env}', 'preview').replace('{space}', API_SPACE_ID).replace('{token}', API_PREVIEW_TOKEN)

  data.debug = {
    data: API_SPACE_ID
  }

  if (data.isReady) {
    return new Promise(res => res(data))
  }
  return axios({
      method: 'GET',
      url: isPreviewURL ? previewEndpointV2 : endpointV2
    })
    .then(res => res.data)
    .then(res => {
      const Types = Object.keys(ComponentTypes).map(t => [ComponentTypes[t], t])

      data.isReady = true
      data.data = res
      data.data.items.forEach(item => {
        const pageTitle = item.fields ? item.fields.pageTitle.trim().toLowerCase().split(' ').join('-') : ''
        item.classes = pageTitle
      })
      data.data.includes.Entry.forEach(entry => {
        const type = Types.find(type => entry.fields[type[0]])
        
        if (type && type.length) {
          entry.componentType = type[1]

          if (entry.fields) {
            const keys = Object.keys(entry.fields)
            
            keys.forEach(key => {
              let field = entry.fields[key]
              if (typeof field !== 'string') {
                if (!field.length && !field.nodeType) {
                  field = [field]
                }
                if (field.length) {
                  field.forEach(f => {
                    if (!f.sys) return

                    let cField = data.data.includes.Entry.find(e => e.sys.id === f.sys.id) || data.data.includes.Asset.find(e => e.sys.id === f.sys.id) || data.data.items.find(e => e.sys.id === f.sys.id)
                    if (cField) {
                      f.fields = cField.fields
                      if (!cField.componentType) {
                        cField.componentType = (Types.find(type => cField.fields[type[0]]) || [])[1]
                      }
                      if (type[1]==='breadcrumbs' && !cField.componentType && cField.fields.pageTitle) {
                        cField.fields.pagePermalink = '/' + cField.fields.pageTitle.trim().toLowerCase().split(' ').join('-')
                        cField.componentType = 'linkComponentItem'
                      }
                      f.componentType = cField.componentType
                    }
                  })
                }
              }
            })
          }
        }
      })
      data.data.items.forEach(item => {
        if (item.fields && item.fields.pageComponents) {
          item.fields.pageComponents.forEach(pageComponent => {
            let fields = data.data.includes.Entry.find(e => e.sys.id === pageComponent.sys.id) || data.data.includes.Asset.find(e => e.sys.id === pageComponent.sys.id)
            if (fields) {
              pageComponent.fields = fields.fields
              pageComponent.componentType = fields.componentType
            }
          })
        }
      })
      return data
    }).catch(err => {
      console.log(err)
    })
}

export const mapUrlPathWithData = (url, data) => {
  if (!data) return
  if (data.items && data.items.length) {
    if (!url || url === '/') {
      return data.items.find(item => item.sys.id === FIXED_HOMEPAGE_ID)
    }
    const normalizeUrl = decodeURI(url.trim().toLowerCase().replace('/', '')).split(' ').join('-')
    return data.items.find(item => {
      const pageTitle = item.fields ? item.fields.pageTitle.trim().toLowerCase().split(' ').join('-') : ''
      return normalizeUrl === pageTitle
    })
  }
}

export const mapIdWithData = (id, data) => {
  if (!id || !data) return
  if (data.items && data.items.length) {
    return data.items.find(item => item.sys.id === id)
  }
}