const parseStringToNumber = stringNumber => ~~(`${stringNumber}`.replace(/,/g, ''))

const formatNumber = number => Math.round(Math.max(0, number)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

export const calcBenefitPerYear = (avgPerVehicle, numOfVehicle, fuelDiscount, qantasPoints) => {
  const avg = parseStringToNumber(avgPerVehicle)
  const num = parseStringToNumber(numOfVehicle)
  const result = { savedPerYear: 0, earnPerYear: 0 }
  if (avg > 0 && num > 0) {
    result.savedPerYear = '$' + formatNumber((avg * num * fuelDiscount * 52) / 100)
    result.earnPerYear = formatNumber(avg * num * (qantasPoints / 2) * 52)
    result.showCalculatorResults = true;
  }
  return result
}