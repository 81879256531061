import React from 'react'

const FuelDetailItem = ({ componentData, onClickHandle, index, selectedIndex }) => {
  if (!componentData.fields) {
    return <></>
  }

  return (
    <div className={['tab-item', selectedIndex === index ? ' active' : ''].join('')} onClick={() => onClickHandle && onClickHandle(index)}>
      <h3 className='title'>{componentData.fields.fuelName}</h3>
      <p className='caption'>{componentData.fields.fuelFullName}</p>
    </div>
  )
}

export default FuelDetailItem
