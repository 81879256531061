import React, { useEffect, useState } from 'react'
import useWindowWidth from '../../hooks/useWindowWidth'
import useWebpSupport from '../../hooks/useWebPSupport'

const Banner = ({ componentData }) => {
  const [bannerURL, setBannerURL] = useState('')
  const windowWidth = useWindowWidth()
  const isWebpSupport = useWebpSupport();
  
  const webp = isWebpSupport ? '?fm=webp&q=80' : '';

  useEffect(() => {
    if (componentData.fields) {
      setBannerURL((componentData.fields.banner[windowWidth < 768 ? 0 : 1] || { fields: { file: { url: '' } } }).fields.file.url)
    }
  }, [windowWidth, componentData.fields])
  if (!componentData.fields) {
    return <></>
  }

  return (
    <div className='content banner__component' style={{
      minHeight: '400px',
      backgroundImage: `url(${bannerURL}${webp})`,
      backgroundRepeat: 'none',
      backgroundSize: 'cover'
    }} />
  )
}

export default Banner
