import axios from 'axios'

let API_BASE_URL = require('../constants/Config').API_MENU_URL
let API_SPACE_ID = process.env.REACT_APP_BP_SPACE_ID
let API_TOKEN = process.env.REACT_APP_BP_ACCESS_TOKEN

const data = {
  isReady: false,
  data: null,
  debug: null
}

export default function getMenu() {
  let endpointV2 = API_BASE_URL.replace('{env}', 'cdn').replace('{space}', API_SPACE_ID).replace('{token}', API_TOKEN)

  data.debug = {
    menu: API_SPACE_ID
  }

  if (data.isReady) {
    return new Promise(res => res(data))
  }
  return axios({
      method: 'GET',
      url: endpointV2
    })
    .then(res => res.data)
    .then(res => {
      data.isReady = true
      data.data = res
      data.data.items.forEach(item => {
        if (item.fields && item.fields.linkComponentItems) {
          item.fields.linkComponentItems.forEach(linkItem => {
            let fields = data.data.includes.Entry.find(e => e.sys.id === linkItem.sys.id)
            if (fields) {
              linkItem.fields = fields.fields
              if (fields.fields.linkComponentItemImage) {
                let subFields = data.data.includes.Asset.find(a => a.sys.id === fields.fields.linkComponentItemImage.sys.id)
                if (subFields) {
                  fields.fields.linkComponentItemImage.fields = subFields.fields
                }
              }
            }
          })
        }
      })
      return data
    }).catch(err => {
      console.log(err)
    })
}