// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {
  37: 1,
  38: 1,
  39: 1,
  40: 1
};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
    get: function () {
      supportsPassive = true;
      return true;
    }
  }));
} catch (e) {}

var wheelOpt = supportsPassive ? {
  passive: false
} : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

const updateCurrent = (prev, next, step) => {
  let current = prev + (next - prev) * step
  document.documentElement.scrollTop = current
  if (Math.abs(current - next) > 0.1) {
    requestAnimationFrame(() => updateCurrent(current, next, step * 1.012))
    return
  }
  document.documentElement.scrollTop = next
  enableScroll()
}

export function getOffsetTopFromHash(hash, exSpacing = 25) {
  const id = (hash || '').split('#')[1] || ''
  const element = document.getElementById(id)
  const headerHeight = (document.querySelector('.header__component .navbar') || {
    offsetHeight: 0
  }).offsetHeight + exSpacing
  return element ? (element.getBoundingClientRect().top - headerHeight) : -1
}

export default function smoothScrollTo(top, step = 0.06) {
  const start = document.documentElement.scrollTop
  const end = top > ~0 ? (start + top) : 0
  const current = start
  disableScroll()
  requestAnimationFrame(() => updateCurrent(current, end, step))
}