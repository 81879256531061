
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import RichTextRenderer from '../richTextRenderer/richTextRenderer';
import './full-width-rich-text.scss';

const FullWidthRichText = ({ componentData, headingId, children }) => {
  if (!componentData.fields && !children) {
    return <></>;
  }

  const richTextCol2 = componentData.fields.richTextColumn2;

  if (children) {
    return (
      <div
        className={[
          'full-width-rich-text__component',
          richTextCol2 ? ' rich-text-2cols' : '',
        ].join('')}
      >
        <div className='container'>
          <h2 id={headingId} className='richtext__outer-head'>
            {componentData.fields.title}
          </h2>
          <div className='richtext__inner'>
            <Row>
              <Col xs={12} md={richTextCol2 ? 6 : 12}>
                {children}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={[
        'full-width-rich-text__component',
        richTextCol2 ? ' rich-text-2cols' : '',
      ].join('')}
    >
      <div className='container'>
        <h2 id={headingId} className='richtext__outer-head'>
          {componentData.fields.title}
        </h2>
        <div className='richtext__inner'>
          <Row>
            <Col xs={12} md={richTextCol2 ? 6 : 12}>
              <RichTextRenderer content={componentData.fields.richText} />
            </Col>
            {richTextCol2 && (
              <Col xs={12} md={6}>
                <RichTextRenderer content={richTextCol2} />
              </Col>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default FullWidthRichText;
