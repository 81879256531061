import React, {useState, useEffect} from 'react'
import './cookie-banner.scss'

const CookieBanner = () => {
  const [isCookieAgree, setIsCookieAgree] = useState()

  const getCookie = (cname) => {
    var name = cname + "="
    var ca = document.cookie.split(';')
    for(var i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }

  const setCookie = (cname, cvalue, exdays) => {
    var d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    var expires = "expires="+d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
  }

  const cookieAgree = () => {
    setCookie('bp-cookie-agree', 'yes', 30)
    setIsCookieAgree('yes')
  }
  
  useEffect(() => {
    if(typeof isCookieAgree === 'undefined'){
      let isAgree = getCookie('bp-cookie-agree')
      setIsCookieAgree(isAgree)
    }
  }, [isCookieAgree]);

  return (
    <div className={ 'bp-cookie-banner ' + (isCookieAgree==='yes' ? 'nodisplay' : '') } >
      <div className="content">
        We use cookies to collect and analyse information on our site’s performance. By accessing this site, you agree to our use of cookies. To see how we use cookies, please see the BP Privacy Policy for more information. For information on cookies, click <a href="http://www.allaboutcookies.org/" target="_blank" rel="noopener noreferrer" tabIndex="1">Learn more</a>
      </div>
      <button className="btn btn-primary" tabIndex="2" onClick={cookieAgree} >OK</button>
    </div>
  )
}

export default CookieBanner