import React from 'react';
import MetaTags from 'react-meta-tags';

const JsonLdMeta = (pageId) => {
  let data = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    "name": "BP Plus",
    "url": "https://www.bpplus.com.au/",
    "logo": "https://images.ctfassets.net/li6zyy5ai965/54EQQ64gettqNh0PaWOna9/9437758fc4f164500ba67c16f0fb1a14/Logo.png",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "1300 760 039",
      "contactType": "customer service",
      "contactOption": "TollFree",
      "areaServed": "AU",
      "availableLanguage": "en"
    },
    "sameAs": [
      "https://www.bp.com/en_au/australia/home/products-services/bp-plus.html",
      "https://www.linkedin.com/company/bp/"
    ]
  };
  let structuredJSON = JSON.stringify(data);

  if(pageId.pageId!=='6HF1DdwghJKWOlfzbcK4MP'){
    return (
      <MetaTags></MetaTags>
    )  
  }

  return (
    <MetaTags>
      <script type="application/ld+json">{structuredJSON}</script>
    </MetaTags>
  )  
}

const exportedObject = (data, path) => {
  let pageData = data.pageData
  
  // let baseUrl = 'https://www.bpplus.com.au'
  let baseUrl = process.env.REACT_APP_BP_BASEURL

  let metaData = {
    url: baseUrl,
    siteName: 'BP-Plus',
    locale: 'en_US',
    description: pageData.fields.metaDescription,
    accTwitter: '@site_account',
    imageTwitter: "https://images.ctfassets.net/li6zyy5ai965/2iInGWt5K09ZG1xvvLWzf2/e640cfc99fe5e29c9ec5bc06f56c614d/bp-plus-card.png",
    imageTwitterAlt: 'alt image text',
    imageFacebook: "https://images.ctfassets.net/li6zyy5ai965/2iInGWt5K09ZG1xvvLWzf2/e640cfc99fe5e29c9ec5bc06f56c614d/bp-plus-card.png",
    imageFacebookAlt: 'alt image text',
    canonical: baseUrl + document.location.pathname
  }
  
  return (
    <>
      <JsonLdMeta pageId={pageData.sys.id} />
      <MetaTags>
        <title>{pageData.fields.metaTitle}</title>
        <meta name="description" content={metaData.description} />
        {pageData.fields.metaRobots && (
          <meta name="robots" content="noindex, nofollow" />
        )}

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={metaData.accTwitter} />
        <meta name="twitter:url" content={metaData.url} />
        <meta name="twitter:title" content={pageData.fields.metaTitle} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image" content={metaData.imageTwitter} />
        <meta name="twitter:image:alt" content={metaData.imageTwitterAlt} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaData.url} />
        <meta property="og:title" content={pageData.fields.metaTitle} />
        <meta property="og:image" content={metaData.imageFacebook} />
        <meta property="og:image:alt" content={metaData.imageFacebookAlt} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:site_name" content={metaData.siteName} />
        <meta property="og:locale" content={metaData.locale} />

        <link rel="canonical" href={metaData.canonical} />
      </MetaTags>
    </>
  )
};

export default exportedObject;