export const text = 'text'
export const listItem = 'list-item'
export const paragraph = 'paragraph'
export const unorderedList = 'unordered-list'
export const orderedList = 'ordered-list'
export const blockquote = 'blockquote'
export const hyperlink = 'hyperlink'
export const hr = 'hr'
export const h1 = 'heading-1'
export const h2 = 'heading-2'
export const h3 = 'heading-3'
export const h4 = 'heading-4'
export const h5 = 'heading-5'
export const h6 = 'heading-6'