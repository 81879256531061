export const accordion = 'accordionTitle'
export const accordionListItem = 'listItemBody'
export const banner = 'banners'
export const fullWidthCarousel = 'carouselItems'
export const carouselItem = 'carouselItemTitle'
export const featureComponent = 'featureComponentItems'
export const featureComponentItem = 'itemDescription'
export const fullWidthRichText = 'richText'
export const linkComponent = 'linkComponentItems'
export const linkComponentItem = 'linkComponentItemTitle'
export const heroCardComponent = 'heroCardRightTitle'
export const heroCardCarousels = 'heroCardCarouselItem'
export const heroCardCarouselItem = 'heroCardCarouselSubtext'
export const fuelComponent = 'fuelDetail'
export const fuelDetailItem = 'fuelName'
export const bpPill = 'pillDetails'
export const bpPillDetail = 'pillImage'
export const breadcrumbs = 'breadcrumbLinks'