import * as Types from './../constants/ActionTypes'
const initalState = {
  isReady: false,
  isOpen: false
}

const menuProducer = (state = initalState, action) => {
  switch (action.type) {
    case Types.GET_MENU__BEGIN:
      return {
        ...state,
        ...action.payload
      };
    case Types.GET_MENU__SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case Types.MENU__CLOSE:
      return {
        ...state,
        isOpen: false
      };
    case Types.MENU__TOGGLE:
      return {
        ...state,
        isOpen: action.payload
      };
    default:
      return {
        ...state
      }
  }
}

export default menuProducer