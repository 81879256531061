import React from 'react'

const targetLinkDetect = url => {
  const target = ['', '_blank']
  let tIndex = 0
  if (url.startsWith('//') || url.startsWith('http')) {
    const host = window.location.host
    const urlHost = url.split('//')[1]
    if (!(urlHost.startsWith(host) || urlHost.split('/')[0].includes(host))) {
      tIndex = 1
    }
  }
  return target[tIndex]
}

const BreadcrumbLinkItem = ({ data, children, className, renderChildOnly }) => {
  if (!data) {
    return <></>
  }
  if (renderChildOnly) {
    return children(data.fields)
  }
  
  let url = data.fields.pagePermalink
  if (!url) {
    return <></>
  }

  return (
    <a className={className} href={url} target={targetLinkDetect(url)} title={ data.fields.pageTitle } >
      {children && children(data.fields)}
    </a>
  )
}

export default BreadcrumbLinkItem
