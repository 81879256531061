import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchAsset = (id) => {
  const [assetUrl, setAssetUrl] = useState(null);
  const [assetTitle, setAssetTitle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAsset = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `https://cdn.contentful.com/spaces/${process.env.REACT_APP_BP_SPACE_ID}/assets/${id}`,
          params: {
            access_token: process.env.REACT_APP_BP_ACCESS_TOKEN
          }
        });

        setAssetUrl(response.data.fields.file.url);
        setAssetTitle(response.data.fields.title);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchAsset();
  }, [id]);

  return { assetUrl, assetTitle, loading, error };
};

export default useFetchAsset;
