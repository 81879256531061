const prefixes = {}

export default class TagIDProvider {
  constructor(role = 'heading') {
    this.rememberedRole = role
  }

  getAnIDFromName(name, uniqueId, role = this.rememberedRole) {
    const convertedName = `${name}-${role}`
    let num = 0
    if (!prefixes[convertedName]) {
      prefixes[convertedName] = {}
    }
    if (!prefixes[convertedName][uniqueId]) {
      num = Object.keys(prefixes[convertedName]).length + 1
      prefixes[convertedName][uniqueId] = num
    } else {
      num = prefixes[convertedName][uniqueId]
    }
    return `${convertedName}-${num}`
  }
}