import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import LinkComponentItem from '../linkComponent/linkComponentItem'
import CarouselItemDesc from './carouselItemDesc'
import useWindowWidth from '../../hooks/useWindowWidth'
import useWebpSupport from '../../hooks/useWebPSupport'

const CarouselItemHeading = ({ color, title, type }) => {
  if(typeof type!=='undefined' && type[0]==='h2'){
    return (
      <h2 className="carousel-heading" style={{ color }}>{title}</h2>
    )
  }

  return (
    <h1 className="carousel-heading" style={{ color }}>{title}</h1>
  )  
}

const CarouselItem = ({ componentData, width }) => {
  const [bannerURL, setBannerURL] = useState('')
  const [bannerALT, setBannerALT] = useState('')
  const windowWidth = useWindowWidth()
  const isWebpSupport = useWebpSupport();
  
  const webp = isWebpSupport ? '?fm=webp&q=80' : '';

  useEffect(() => {
    if (componentData.fields) {
      const prop = ['desktopBackground', 'mobileBackground']
      setBannerURL((componentData.fields[windowWidth >= 768 ? prop[0] : prop[1]] || { fields: { file: { url: '' } } }).fields.file.url)
      setBannerALT((componentData.fields[windowWidth >= 768 ? prop[0] : prop[1]] || { fields: { file: { url: '' } } }).fields.title)
    }
  }, [windowWidth, componentData.fields])
  if (!componentData.fields) {
    return <></>
  }

  return (
    <div className='content row' style={{
      marginLeft: `${-width}px`,
      marginRight: `${-width}px`,
      paddingLeft: `${width}px`,
      paddingRight: `${width}px`,
      backgroundImage: `url(${bannerURL}${webp})`
    }}>
      <span role='img' aria-label={bannerALT} />
      <div className='col-lg-7 col-md-12'>
        <CarouselItemHeading color={componentData.fields.carouselItemTitleColor} title={componentData.fields.carouselItemTitle} type={componentData.fields.carouselItemTitleSelector}/>
        <CarouselItemDesc componentData={{
          color: componentData.fields.carouselItemSubtitleColor,
          shortDesc: componentData.fields.carouselItemShortDescription,
          subDesc: componentData.fields.carouselItemSubtitle
        }} />
      </div>
      <div className='col-lg-12 col-md-12'>
        <LinkComponentItem data={componentData} linkProp='carouselItemLink' children={
          fields => <Button className='btn-bpPrimary find-out-more'>{fields.carouselItemLinkTitle}</Button>
        } />
      </div>
    </div>
  )
}

export default CarouselItem
