import React, { useRef } from 'react'
import AccordionListItem from './accordionListItem'
import TagIDProvider from '../../providers/TagIDProvider'
import './accordion.scss'
import classNames from '../../utils/classNames'

const tagID = new TagIDProvider('title')

const Accordion = ({ componentData, headingId }) => {
  const parentNode = useRef()
  if (!componentData.fields) {
    return <></>
  }
  const accordionListItem = componentData.fields.accordionListItem || []

  return (
    <div className='accordion__component' ref={parentNode}>
      <div className='container'>
        <h2
          id={headingId}
          className={classNames(
            'accordion-title',
            !componentData.fields.accordionTitle?.trim() && 'pt-1 pt-lg-3'
          )}
        >
          {componentData.fields.accordionTitle}
        </h2>
        {
          accordionListItem.map((item, i) => (
            <AccordionListItem
              key={i}
              parentNode={parentNode}
              componentData={item}
              headingId={tagID.getAnIDFromName(componentData.componentType, item.sys.id)}
              index={i}
              total={accordionListItem.length}
            />
          ))
        }
      </div>
    </div>
  )
}

export default Accordion
