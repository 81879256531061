import RichTextRenderFuncName from '../constants/RichTextRenderFuncName'
import * as RichTextNodeTypes from '../constants/RichTextNodeTypes'

const revertedKeyValueNodeTypes = Object.keys(RichTextNodeTypes).map(i => ({
  [RichTextNodeTypes[i]]: i
})).reduce((acc, cur) => ({
  ...acc,
  ...cur
}), {})

const exportedObject = {
  renderParagraph: function (text) {
    const content = text.content || []
    const result = content.map(item => {
      return this.mapRenderOnType.call(this, item)
    }).join('')
    return `<p>${result}</p>`
  },
  renderUnorderedList: function (text) {
    const content = text.content || []
    const result = content.map(item => {
      return this.mapRenderOnType.call(this, item)
    }).join('')
    return `<ul>${result}</ul>`
  },
  renderOrderedList: function (text) {
    const content = text.content || []
    const result = content.map(item => {
      return this.mapRenderOnType.call(this, item)
    }).join('')
    return `<ol>${result}</ol>`
  },
  renderListItem: function (text) {
    const content = text.content || []
    const result = content.map(item => {
      return this.mapRenderOnType.call(this, item)
    }).join('')
    return `<li>${result}</li>`
  },
  renderText: function (text) {
    let result = text.value
    if (text.marks && text.marks.length) {
      const tags = text.marks.reduce((acc, cur) => {
        const type = cur.type === 'code' ? cur.type : cur.type[0]
        acc.open.push(`<${type}>`)
        acc.close.push(`</${type}>`)
        return acc
      }, {
        open: [],
        close: []
      })
      result = `${tags.open.map(o => o).join('')}${text.value}${tags.close.reverse().map(c => c).join('')}`
    }
    return result
  },
  renderHyperlink: function (text) {
    const content = text.content || []
    const result = content.map(item => {
      return this.mapRenderOnType.call(this, item)
    }).join('')
    const url = text.data ? text.data.uri : ''
    return `<a href='${url}' target='_blank'>${result}</a>`
  },
  renderHR: () => {
    return '<hr/>'
  },
  renderHeading: function (text) {
    const content = text.content || []
    const result = content.map(item => {
      return this.mapRenderOnType.call(this, item)
    }).join('')
    return `<${revertedKeyValueNodeTypes[text.nodeType]}>${result}</${revertedKeyValueNodeTypes[text.nodeType]}>`
  },
  renderBlockquote: function (text) {
    const content = text.content || []
    const result = content.map(item => {
      return this.mapRenderOnType.call(this, item)
    }).join('')
    return `<blockquote>${result}</blockquote>`
  },
  mapRenderOnType: function (item) {
    if (!this[RichTextRenderFuncName[item.nodeType]]) {
      return `<br/><span style='color: red; font-weight: bold;'>[Unknow richText type, view console for more information]</span><br/>`
    }
    return this[RichTextRenderFuncName[item.nodeType]].call(this, item)
  }
};

export default exportedObject;