import React from 'react'
import { connect } from 'react-redux'
import { ListGroup, Image } from 'react-bootstrap'
import { MAP_MENU_LINK_NAME } from '../../constants/Config'
import * as Types from '../../constants/ActionTypes'
import LinkComponentItem from '../linkComponent/linkComponentItem'

const ConnectedMenuLink = ({ menuLink, closeMenu }) => {
  if (!menuLink || !menuLink.fields) {
    return <></>
  }

  return (
    <ListGroup variant='flush' className='mt-5'>
      {
        menuLink.fields.linkComponentItems.map((item, i) => (
          <ListGroup.Item key={i}>
            <LinkComponentItem data={item} onClickHandle={closeMenu} children={
              fields => (
                <>
                  <div className='item-lable d-inline-block'>{fields.linkComponentItemLinkTitle}</div>
                  <div className='d-inline-block' style={{ float: 'right' }}>
                    <Image src='/assets/icons/arrow-nav.svg' width={19} height={20} loading='lazy' decoding='async' alt='Small white arrow pointing right' />
                  </div>
                </>
              )
            } />
          </ListGroup.Item>
        ))
      }
    </ListGroup>
  )
}

const mapStateToProps = state => ({
  menuLink: state.menu.data.items.find(item => item.fields.title === MAP_MENU_LINK_NAME)
})

const mapDispatchToProps = dispatch => ({
  closeMenu: () => dispatch({ type: Types.MENU__CLOSE })
})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedMenuLink)
