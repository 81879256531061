import React from 'react'
import { Carousel, Card } from 'react-bootstrap'
import HeroCardCarouselItem from './heroCardCarouselItem'

const HeroCardCarousels = ({ componentData }) => {
  return (
    <Carousel interval={null}>
      {
        (componentData || []).map((carousels, i) => (
          <Carousel.Item key={i}>
            <Card>
              <Card.Body>
                {
                  (carousels.fields.heroCardCarouselItem || []).map((item, j) => <HeroCardCarouselItem key={j} componentData={item} />)
                }
              </Card.Body>
            </Card>
          </Carousel.Item>
        ))
      }
    </Carousel>
  )
}

export default HeroCardCarousels
