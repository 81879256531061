import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import RemoteVideoModal from '../videoModal/remoteVideoModal';
import AssetVideoModal from '../videoModal/assetVideoModal';
import IconDownload from '../icons/iconDownload';

const renderOptions = {
  // Link from internal asset
  [INLINES.ASSET_HYPERLINK]: (node) => {
    return (
      <AssetVideoModal
        linkText={node.content[0].value}
        videoId={node.data.target.sys.id}
      />
    );
  },

  // Link from remote server
  [INLINES.HYPERLINK]: (node) => {
    if (
      node.data.uri.includes('youtube.com') ||
      node.data.uri.includes('vimeo.com')
    ) {
      return (
        <RemoteVideoModal
          linkText={node.content[0].value}
          videoUrl={node.data.uri}
        />
      );
    } else {
      if (node.data.uri.includes('.pdf')) {
        return (
          <a href={node.data.uri} aria-label='Download this file.' className='ml-1 p-1'>
            <IconDownload />
          </a>
        );
      }

      return <a href={node.data.uri}>{node.content[0].value}</a>;
    }
  },
  [BLOCKS.PARAGRAPH]: (_, children) => {
    const isEmptyChildren = !children?.toString()?.trim()
  
    if (isEmptyChildren) return null
  
    return <p>{children}</p>
  },
};

const RichTextRenderer = ({ content }) => {
  return (
    <>
      {documentToReactComponents(content, {
        renderNode: renderOptions,
      })}
    </>
  );
}

export default RichTextRenderer;