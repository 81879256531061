import React, { useEffect, useState } from 'react'
import LinkComponentItem from '../linkComponent/linkComponentItem'
import useWindowWidth from '../../hooks/useWindowWidth'
import useWebpSupport from '../../hooks/useWebPSupport'

const FeatureComponentBanner = ({ componentData: { banners, subTitle, body, link } }) => {
  const [bannerURL, setBannerURL] = useState('')
  const [bannerALT, setBannerALT] = useState('')
  const windowWidth = useWindowWidth()
  const isWebpSupport = useWebpSupport();
  
  const webp = isWebpSupport ? '?fm=webp&q=80' : '';

  useEffect(() => {
    if (banners) {
      setBannerURL((banners[windowWidth >= 768 ? 0 : 1] || { fields: { file: { url: '' } } }).fields.file.url)
      setBannerALT((banners[windowWidth >= 768 ? 0 : 1] || { fields: { file: { url: '' } } }).fields.title)
    }
  }, [windowWidth, banners])
  if (!banners || !banners.length) {
    if (!body) {
      return <></>
    }
    return <p className='weve-partnered-with'>{body}</p>
  }

  return (
    <div className='feature-component-banner'>
      <div>
        <img src={`${bannerURL}${webp}`} loading='lazy' decoding='async' className='banner-bg d-block' alt={bannerALT} style={{ objectFit: 'cover', objectPosition: 'top' }} />
      </div>
      <div className='bg-bottom' style={{ position: 'relative' }}>
        <div className='service-fees'>
          <h4 className='service-fees-with'>{subTitle}</h4>
          <p className='switch-your-payment'>{body}</p>
          <LinkComponentItem data={link} linkProp='url' className='d-inline-block find-out-more' children={
            () => (
              <>
                <span>{link.label}</span>
                <img src='/assets/icons/arrow-second.svg' loading='lazy' decoding='async' width={40} height={20}  alt='Arrow' id='arrow-second' className='d-inline-block ml-3' />
              </>
            )
          } />
        </div>
      </div>
    </div>
  )
}

export default FeatureComponentBanner
