import React from 'react'
import LinkComponentItem from '../linkComponent/linkComponentItem'
import useWebpSupport from '../../hooks/useWebPSupport';

const BPPillDetail = ({ componentData }) => {
  const isWebpSupport = useWebpSupport();
  
  const webp = isWebpSupport ? '?fm=webp&q=80' : '';

  if (!componentData.fields) {
    return <></>
  }
  const image = componentData.fields.pillImage || { fields: { file: { url: '' } } }

  return (
    <li>
      <LinkComponentItem data={componentData} linkProp='imageLink' children={() => <img src={`${image.fields.file.url}${webp}`} width={54} height={54} loading='lazy' decoding='async' alt={image.fields.title} />} />
    </li>
  )
}

export default BPPillDetail
