import React from 'react'

const HeroCardCarouselItemText = ({ head, subtext }) => (
  <div>
    <div style={{ height: '55px' }} className='mx-auto'>
      <h2 className='amount'>{head}</h2>
    </div>
    <div className='joining-fee mx-auto mt-2'>{subtext}</div>
  </div>
)

const HeroCardCarouselItemImage = ({ image, subtext }) => (
  <div>
    <div className='mx-auto carousel-item-image'>
      <picture>
        <source srcSet={`${image.fields.file.url}?fm=webp&q=80`} type="image/webp" />
        <img src={image.fields.file.url} alt={image.fields.title} width={91} height={27} loading='lazy' decoding='async' /> 
      </picture>
    </div>
    <div className='joining-fee mx-auto mt-2'>{subtext}</div>
  </div>
)

const HeroCardCarouselItem = ({ componentData }) => {
  if (!componentData.fields) {
    return <></>
  }
  const ItemRealContent = () => componentData.fields.heroCardCarouselImage ?
    <HeroCardCarouselItemImage image={componentData.fields.heroCardCarouselImage} subtext={componentData.fields.heroCardCarouselSubtext} /> :
    <HeroCardCarouselItemText head={componentData.fields.heroCardCarouselItemHead} subtext={componentData.fields.heroCardCarouselSubtext} />

  return (
    <div className='item'>
      <ItemRealContent />
    </div>
  )
}

export default HeroCardCarouselItem
