import {
  combineReducers
} from 'redux'
import data from './data'
import menu from './menu'

const rootReducer = combineReducers({
  data,
  menu
})

export default rootReducer