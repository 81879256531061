import React from 'react'
import BreadcrumbLinkItem from './breadcrumbLinkItem'

import './breadcrumbs.scss'

const Breadcrumbs = (data, path) => {
  if (!data.componentData.fields) {
    return <></>
  }

  // componentData.fields.breadcrumbLinks
  const links = data.componentData.fields.breadcrumbLinks
  
  return (
    <div className='breadcrumb__component'>
      <div className='container'>

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {
              links.map((link, i) => (
                <li key={i} className={ "breadcrumb-item" + (i>=(links.length-1) ? ' current' : '') } aria-current={ (i>=(links.length-1) ? 'page' : null) }>
                  <BreadcrumbLinkItem className='breadcrumb-item' data={link} renderChildOnly={ (i>=(links.length-1) ? true : false ) } children={
                    fields => (
                      <>
                        <span className='breadcrumb-text'>{ fields.pageTitle }</span>
                      </>
                    )
                  } />
                </li>
              ))
            }
          </ol>
        </nav>

      </div>
    </div>
  )
}

export default Breadcrumbs