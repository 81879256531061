import React from 'react'
import BPPillDetail from './bpPillDetail'
import './bp-pill.scss'

const BPPill = ({ componentData }) => {
  if (!componentData.fields) {
    return <></>
  }

  return (
    <div className="bp-pill__component">
      <ul>
        {
          (componentData.fields.pillDetails || []).map((item, i) => <BPPillDetail componentData={item} key={i} />)
        }
      </ul>
    </div>
  )
}

export default BPPill
