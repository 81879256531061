import * as Types from '../constants/ActionTypes'
import dataProvider from '../providers/DataProvider'
import menuProvider from '../providers/MenuProvider'

export const getData = isPreviewURL => {
  const getData__Begin = () => ({
    type: Types.GET_DATA__BEGIN,
    payload: {
      isReady: false,
      data: null
    }
  })

  const getData__Success = data => ({
    type: Types.GET_DATA__SUCCESS,
    payload: {
      ...data
    }
  })

  const getData__Error = err => ({
    type: Types.GET_DATA__FAIL,
    payload: {
      err
    }
  })
  return dispatch => {
    dispatch(getData__Begin())
    dataProvider(isPreviewURL)
      .then(data => dispatch(getData__Success(data)))
      .catch(err => dispatch(getData__Error(err)))
  }
}

export const getMenu = () => {
  const getMenu__Begin = () => ({
    type: Types.GET_MENU__BEGIN,
    payload: {
      isReady: false,
      data: null
    }
  })

  const getMenu__Success = data => ({
    type: Types.GET_MENU__SUCCESS,
    payload: {
      ...data
    }
  })

  const getMenu__Error = err => ({
    type: Types.GET_MENU__FAIL,
    payload: {
      err
    }
  })
  return dispatch => {
    dispatch(getMenu__Begin())
    menuProvider()
      .then(data => dispatch(getMenu__Success(data)))
      .catch(err => dispatch(getMenu__Error(err)))
  }
}