import React from 'react'
import LinkComponentItem from './linkComponentItem'
import { Button, Image } from 'react-bootstrap'
import LinkComponentRichTextItem from './linkComponentRichTextItem'
import './link-component.scss'

const LinkComponent = ({ componentData }) => {
  if (!componentData.fields) {
    return <></>
  }
  if (!componentData.fields.linkComponent) {
    componentData.fields.linkComponent = {
      fields: {
        linkComponentItems: []
      }
    }
  }
  const links = componentData.fields.linkComponent.fields.linkComponentItems
  const richTexts = componentData.fields.linkComponentItems || []
  const CTA = componentData.fields.linkComponent.fields.linkComponent || {}

  return (
    <div className='link-component__component'>
      <div className='login-faq-contactus'>
        <div className='container'>
          <div className='link-list'>
            {
              links.map((link, i) => (
                <div key={i} className='link'>
                  <LinkComponentItem className='log-in' data={link} children={
                    fields => (
                      <>
                        <span className='d-inline-block'>{fields.linkComponentItemLinkTitle || fields.linkComponentItemTitle}</span>
                        <Image src='/assets/icons/arrow-primary.svg' width={42} height={30} loading='lazy' decoding='async' alt='Large arrow pointing right' className='d-inline-block ml-3' />
                      </>
                    )
                  } />
                </div>
              ))
            }
          </div>
          {
            richTexts.map((richText, i) => <LinkComponentRichTextItem key={i} componentData={richText} />)
          }
        </div>
      </div>
      <div className='box-apply-to-bp-plust-now'>
        {
          <LinkComponentItem data={CTA} children={
            fields => <Button variant='success' className='btn-bpPrimary btn btn-primary'>{fields.linkComponentItemLinkTitle || fields.linkComponentItemTitle}</Button>
          } />
        }
      </div>
    </div>
  )
}

export default LinkComponent