import React, { useState } from 'react'
import { Accordion, Button, Image } from 'react-bootstrap'
import LinkComponentItem from '../linkComponent/linkComponentItem'
import RichTextRenderer from '../richTextRenderer/richTextRenderer';

const SubTitle = ({ text }) => (text ? <pre className='item-description'>{text}</pre> : (<></>))

const BottomHR = ({ index, total, isCollapse }) => (((index < total - 1) && isCollapse) && (<hr className='hr-custom' />))

const CollapseHandler = ({ parentNode, componentData: { setWidth, setIsCollapse, isCollapse, link } }) => {
  const toggleCollapse = () => {
    if (isCollapse) {
      if (parentNode.current) {
        const outerWidth = parentNode.current.clientWidth
        const containerWidth = parentNode.current.querySelector('.container').clientWidth
        setWidth((outerWidth - containerWidth) / 2)
      }
    } else {
      setWidth(0)
    }
    setIsCollapse(!isCollapse)
  }

  return (
    <div className='btn-wrapper'>
      <Accordion.Toggle as={Button} variant='light' eventKey='0' className='btn-toggle' onClick={toggleCollapse}>
        <Image className={`${isCollapse ? 'collapsed' : 'expanded'}`} src='/assets/icons/btn-add.svg' width={50} height={50} loading='lazy' decoding='async' alt='Add icon' />
      </Accordion.Toggle>
      <LinkComponentItem data={link} linkProp='url' children={() => <Button className='btn-bpWhiteOutline btn-mobile'>{link.label}</Button>} />
    </div>
  )
}

const AccordionItemContent = ({ componentData: { isCollapse, listItemBody, link } }) => {
  if (isCollapse) {
    return <></>
  }

  return (
    <div>
      <Accordion.Collapse eventKey='0'>
        <pre className='item-description full-width-rich-text__component' >
          <RichTextRenderer content={listItemBody} />          
        </pre>
      </Accordion.Collapse>
      <LinkComponentItem data={link} linkProp='listItemButtonLink' children={
        () => <Button className='btn-bpWhiteOutline mt-3' style={{ width: '220px' }}>{link.accordionItemButtonLabel}</Button>
      } />
    </div>
  )
}

const AccordionListItem = ({ componentData, headingId, index, total, parentNode }) => {
  const [isCollapse, setIsCollapse] = useState(true)
  const [width, setWidth] = useState(0)
  if (!componentData.fields) {
    return <></>
  }
  const imgALT = componentData.fields.listItemImage ? componentData.fields.listItemImage.fields.title : ''
  let imgURL = componentData.fields.listItemImage ? componentData.fields.listItemImage.fields.file.url : ''
  
  if (imgURL && !imgURL.includes('.svg')) {
    imgURL = imgURL + '?fm=webp&q=80';
  }

  return (
    <Accordion className={!isCollapse && 'box-separator'} style={{
      marginLeft: `${-width}px`,
      marginRight: `${-width}px`,
      paddingLeft: `${width}px`,
      paddingRight: `${width}px`
    }}>
      <div className='accordion-inner'>
        {imgURL && 
          <div className='icon'>
            <Image src={imgURL} width={75} height={94} loading='lazy' decoding='async' alt={imgALT} />
          </div>
        }

        <div className={imgURL ? 'desc' : 'pl-0 col-11'}>
          <h4 id={headingId} className='item-title'>{componentData.fields.listItemTitle}</h4>
          <SubTitle text={componentData.fields.listItemSubtitle} />
          <AccordionItemContent componentData={{
            isCollapse,
            listItemBody: componentData.fields.listItemBody,
            link: {
              listItemButtonLink: componentData.fields.listItemButtonLink,
              accordionItemButtonLabel: componentData.fields.accordionItemButtonLabel
            }
          }} />
        </div>
        <CollapseHandler parentNode={parentNode} componentData={{
          setWidth,
          isCollapse,
          setIsCollapse,
          link: {
            url: componentData.fields.listItemButtonLink,
            label: componentData.fields.accordionItemButtonLabel
          }
        }} />
      </div>
      <BottomHR index={index} total={total} isCollapse={isCollapse} />
    </Accordion>
  )
}

export default AccordionListItem
