import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import './video-modal.scss';

const RemoteVideoModal = ({ linkText, videoUrl }) => {
  const modalRef = useRef(null);

  const [modalVisible, setModalVisible] = useState(false);
  const [videoId, setVideoId] = useState(null);
  const [videoFrom, setVideoFrom] = useState('');

  const openModal = (e) => {
    e.preventDefault();
    setModalVisible(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setModalVisible(false);
  };

  useEffect(() => {
    if (modalVisible) {
      if (modalRef.current) {
        modalRef.current.focus();
      }
    }
  }, [modalVisible]);

  useEffect(() => {
    if (videoUrl.includes('youtube.com')) {
      const regex =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = regex.exec(videoUrl);
      const videoId = match && match[7].length === 11 ? match[7] : null;
      setVideoId(videoId);
      setVideoFrom('youtube');
    } else if (videoUrl.includes('vimeo.com')) {
      const regex = /vimeo\.com\/(\d+)/;
      const match = videoUrl.match(regex);
      const videoId = match ? match[1] : null;
      setVideoId(videoId);
      setVideoFrom('vimeo');
    }
  }, [videoUrl]);

  return (
    <>
      <a href='/' onClick={openModal} aria-label={`Play ${videoFrom} video`}>
        {linkText}
      </a>

      {modalVisible &&
        createPortal(
          <div>
            <div
              className='modal fade show video-modal'
              role='dialog'
              style={{ display: 'block' }}
              ref={modalRef}
              tabIndex='0'
            >
              <div
                className='modal-dialog modal-dialog-centered modal-xl'
                role='document'
              >
                <div className='modal-content'>
                  <div className='modal-header'>
                    <button
                      type='button'
                      className='close'
                      onClick={closeModal}
                      aria-label='Close video player'
                    >
                      <span aria-hidden='true'>&times;</span>
                    </button>
                  </div>
                  <div className='modal-body'>
                    {videoId && (
                      <div className='embed-responsive embed-responsive-16by9'>
                        {videoFrom === 'youtube' && (
                          <iframe
                            className='embed-responsive-item'
                            title={linkText}
                            src={`https://youtube.com/embed/${videoId}?autoplay=1&mute=1`}
                            allow='autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                          />
                        )}

                        {videoFrom === 'vimeo' && (
                          <iframe
                            className='embed-responsive-item'
                            title={linkText}
                            src={`https://player.vimeo.com/video/${videoId}?autoplay=1&mute=1`}
                            allow='autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-backdrop fade show'></div>
          </div>,
          document.body
        )}
    </>
  );
};

export default RemoteVideoModal;
