import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from './components/page/page'
import Header from './components/header/header'
import { getData, getMenu } from './actions/index'
import smoothScrollTo, { getOffsetTopFromHash } from './providers/SmoothScrollProvider'

const App = props => {
  const dispatch = useDispatch(store => store)
  const data = useSelector(state => state.data)
  const menu = useSelector(state => state.menu)
  const [pageReady, setPageReady] = useState(false)
  const hash = props.location.hash

  useEffect(() => {
    dispatch(getData(props.match.params.id))
    dispatch(getMenu())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (data.isReady && menu.isReady) {
      setPageReady(true)
    }
  }, [data, menu])
  useEffect(() => {
    if (pageReady) {
      smoothScrollTo(getOffsetTopFromHash(hash))
    }
  }, [pageReady, hash])

  return (
    <>
      <Header />
      <Page id={props.match.params.id} path={props.match.url} hash={props.location.hash} />
    </>
  )
}

export default App
