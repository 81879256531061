import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import useFetchAsset from '../../hooks/useFetchAsset';
import './video-modal.scss';
import IconDownload from '../icons/iconDownload';
import IconPlay from '../icons/iconPlay';

const AssetVideoModal = ({ linkText, videoId }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const modalRef = useRef(null);

  const { assetUrl, assetTitle, loading } = useFetchAsset(videoId);

  const openModal = (e) => {
    e.preventDefault();
    setModalVisible(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setModalVisible(false);
  };

  useEffect(() => {
    if (modalVisible) {
      if (modalRef.current) {
        modalRef.current.focus();
      }
    }
  }, [modalVisible]);

  const videoExtensionPattern =
    /\.(mp4|avi|mkv|mov|wmv|flv|webm|m4v|mpeg|mpg|3gp|ogv)$/i;

  if (loading) {
    return null;
  }

  const hasVideoExtension = videoExtensionPattern.test(assetUrl);

  // Documents
  if (hasVideoExtension === false) {
    return (
      <a href={assetUrl} aria-label='Download this file.' target='_blank' rel='noreferrer' className='ml-1 p-1'>
        <IconDownload />
      </a>
    );
  }

  // Video
  return (
    <>
      <a href='/' onClick={openModal} aria-label={`Play ${assetTitle} video`} className="ml-1 p-1">
        <IconPlay />
      </a>

      {modalVisible &&
        createPortal(
          <div>
            <div
              className='modal fade show video-modal'
              role='dialog'
              style={{ display: 'block' }}
              ref={modalRef}
              tabIndex='0'
            >
              <div
                className='modal-dialog modal-dialog-centered modal-xl'
                role='document'
              >
                <div className='modal-content'>
                  <div className='modal-header'>
                    <button
                      type='button'
                      className='close'
                      onClick={closeModal}
                      aria-label='Close video player'
                    >
                      <span aria-hidden='true'>&times;</span>
                    </button>
                  </div>
                  <div className='modal-body'>
                    <div className='embed-responsive embed-responsive-16by9'>
                      <video
                        controls
                        className='embed-responsive-item'
                        autoPlay={true}
                      >
                        <source src={`${assetUrl}`} type='video/mp4' />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-backdrop fade show'></div>
          </div>,
          document.body
        )}
    </>
  );
};

export default AssetVideoModal;
