import React, { useState } from 'react'
import { Accordion, Image } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import RichTextRenderer from '../richTextRenderer/richTextRenderer';

const LinkComponentRichTextItem = ({ componentData }) => {
  const [isCollapse, setIsCollapse] = useState(true)
  if (!componentData.fields) {
    return <></>
  }

  return (
    <Accordion className='terms-conditions mt-4'>
      <div className='d-inline-block'>
        {componentData.fields.linkComponentItemLinkTitle || componentData.fields.linkComponentItemTitle}
      </div>
      <div className='d-inline-block' style={{ float: 'right' }}>
        <Accordion.Toggle as={Button} variant='outline-light' eventKey='0' className='btn-toggle' onClick={() => setIsCollapse(!isCollapse)}>
          <Image src={`/assets/icons/icon-${isCollapse ? 'down' : 'up'}.svg`} width={18} height={11} loading='lazy' decoding='async'  alt={`${isCollapse ? 'Chevron pointing down' : 'Chevron pointing up'}`} />
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey='0'>
        <pre className='pt-4'>
          <div>
            <RichTextRenderer content={componentData.fields.linkComponentItemBody} />
          </div>
        </pre>
      </Accordion.Collapse>
    </Accordion>
  )
}

export default LinkComponentRichTextItem
