import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createStore, compose, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import App from './App'
import rootReducer from './reducers/index'
import { PREVIEW_ROOT_URL } from './constants/Config'
import { hydrate, render } from "react-dom";
import './assets/scss/styles.scss'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunk))
)

const APP = (<Provider store={store}>
  <Router>
    <Switch>
      <Route exact path='/' component={App} />
      <Route exact path='/:url' component={App} />
      <Route exact path={`${PREVIEW_ROOT_URL}/:id`} component={App} />
      <Route path='/*/*' component={App} />
    </Switch>
  </Router>
</Provider>);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}