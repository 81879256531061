import React, { useState, useRef, useEffect } from 'react'
import { Carousel } from 'react-bootstrap'
import CarouselItem from './carouselItem'
import useWindowWidth from '../../hooks/useWindowWidth'
import './full-width-carousel.scss'


const FullWidthCarousel = ({ componentData }) => {
  const [width, setWidth] = useState(0)
  const parentNode = useRef()
  const windowWidth = useWindowWidth()
  useEffect(() => {
    if (parentNode.current) {
      const outerWidth = parentNode.current.clientWidth
      const containerWidth = parentNode.current.querySelector('.container').clientWidth
      setWidth((outerWidth - containerWidth) / 2)
    }
  }, [windowWidth])
  if (!componentData.fields) {
    return <></>
  }

  const carouselItems = componentData.fields.carouselItems || []

  return (
    <div className='full-width-carousel__component' ref={parentNode}>
      <div className='container customize-width'>
        {
          <Carousel indicators={carouselItems.length > 1}>
            {
              carouselItems.map((item, i) => (
                <Carousel.Item key={i}>
                  <CarouselItem componentData={item} width={width} />
                </Carousel.Item>
              ))
            }
          </Carousel>
        }
      </div>
    </div>
  )
}

export default FullWidthCarousel
