import React from 'react'
import { Image } from 'react-bootstrap'
import MetaTags from 'react-meta-tags'
import FullWidthRichText from '../fullWidthRichText/fullWidthRichText'

let componentData = {
  fields: false
}

const Page404 = () => (
  <>
    <MetaTags>
      <title>Page not Found</title>
      <meta name="description" content="Page not found." />
    </MetaTags>

    <FullWidthRichText componentData={componentData}>
    	<h1 id="featureComponent-heading-1" class="feature-component-title">We were unable to find this page</h1>
    	<div class="feature-component__component">
    		<div class="feature-item">
      		<h3 class="accordion__component"><span class="item-title">{window.location.href}</span></h3>
      	</div>
      </div>		
      	<p>Since this page doesn't exist, perhaps try one of the below:</p>
      <div className='links'>
        <a href="https://www.bpplus.com.au" className='links-404'>
          <span className='d-inline-block'>Home</span>
          <Image src='/assets/icons/arrow-primary-small.svg' alt='Small arrow pointing right' className='d-inline-block ml-3' />
        </a>
        <br></br>
        <a href="https://www.bpplus.com.au/qbr" className='links-404'>
          <span className='d-inline-block'>QBR</span>
          <Image src='/assets/icons/arrow-primary-small.svg' alt='Small arrow pointing right' className='d-inline-block ml-3' />
        </a>
        <br></br>
        <a href="https://www.bpplus.com.au/bp-plus-american-express-offer" className='links-404'>
          <span className='d-inline-block'>American Express</span>
          <Image src='/assets/icons/arrow-primary-small.svg' alt='Small arrow pointing right' className='d-inline-block ml-3' />
        </a>
        <br></br>
        <a href="https://www.bpplus.com.au/bp-plus-xero" className='links-404'>
          <span className='d-inline-block'>Xero</span>
          <Image src='/assets/icons/arrow-primary-small.svg' alt='Small arrow pointing right' className='d-inline-block ml-3' />
        </a>
      </div>
    </FullWidthRichText>
  </>
)

export default Page404
