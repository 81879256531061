import React from 'react'
import RichTextRenderer from '../richTextRenderer/richTextRenderer';

const CarouselItemDesc = ({ componentData: { shortDesc, color, subDesc } }) => {
  if (shortDesc) {
    return (
      <div style={{ color }} className='short-desc' >
        <RichTextRenderer content={shortDesc} />
      </div>
    )
  }

  return (
    <h6 className='pt-6r' style={{ color }}>{subDesc}</h6>
  )
}

export default CarouselItemDesc
