import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Image, Row } from 'react-bootstrap'
import HeroCardCarousels from './heroCardCarousels'
import LinkComponentItem from '../linkComponent/linkComponentItem'
import './hero-card-component.scss'
import useWindowWidth from '../../hooks/useWindowWidth'
import RichTextRenderer from '../richTextRenderer/richTextRenderer';
import useWebpSupport from '../../hooks/useWebPSupport'

const HeroCardComponentHeading = ({ componentData, headingId }) => {
  if(typeof componentData.fields.heroCardRightTitleSelector!=='undefined' && componentData.fields.heroCardRightTitleSelector[0]==='h1'){
    return (
      <>
        <h1 id={headingId} className='bp-plus-fuel-card'>{componentData.fields.heroCardRightTitle}</h1>
      </>
    )
  }

  return (
    <>
      <h2 id={headingId} className='bp-plus-fuel-card'>{componentData.fields.heroCardRightTitle}</h2>
    </>
  )  
}

const HeroCardComponent = ({ componentData, headingId }) => {
  const [bannerURL, setBannerURL] = useState('')
  const [bannerALT, setBannerALT] = useState('')
  const windowWidth = useWindowWidth()
  const isWebpSupport = useWebpSupport();
  
  const webp = isWebpSupport ? '?fm=webp&q=80' : '';

  useEffect(() => {
    if (componentData.fields) {
      const prop = ['bannerDesktop', 'bannerMobile']
      setBannerURL((componentData.fields[windowWidth >= 768 ? prop[0] : prop[1]] || { fields: { file: { url: '' } } }).fields.file.url)
      setBannerALT((componentData.fields[windowWidth >= 768 ? prop[0] : prop[1]] || { fields: { file: { url: '' } } }).fields.title)
    }
  }, [windowWidth, componentData.fields])
  if (!componentData.fields) {
    return <></>
  }
  const imgURL = componentData.fields.heroCardCarouselImage ? componentData.fields.heroCardCarouselImage.fields.file.url : ''
  const imgALT = componentData.fields.heroCardCarouselImage ? componentData.fields.heroCardCarouselImage.fields.title : ''
  const heroCardCarousels = componentData.fields.heroCardCarousels || []
  const heroCardRightContent = (componentData.fields.heroCardRightContent || { fields: {} })

  return (
    <>
      <div>
        <span className='hero-card-component-masthead d-block' role='img' aria-label={bannerALT} style={{ backgroundImage: `url(${bannerURL}${webp})` }} />
      </div>
      <div className='hero-card-component__component'>
        <div className='container' style={{ position: 'relative' }}>
          <Card className='box-bp-plus-fuel-card'>
            <Row>
              <Col className='col-12 col-md-6'>
                <Card className='card-left'>
                  <Card className='card-top'>
                    <Image src={`${imgURL}${webp}`} width={344} height={228} loading='lazy' decoding='async' alt={imgALT} />
                  </Card>
                  <HeroCardCarousels componentData={heroCardCarousels} />
                  <div className='text-center wrap-link'>
                    <a className='tnc-link' href='http://bp.com.au/conditions' target='_blank' rel='noopener noreferrer'>Terms & conditions apply</a>
                  </div>
                </Card>
              </Col>
              <Col className='col-12 col-md-6'>
                <Card className='card-right'>
                  <Card.Body>
                    <HeroCardComponentHeading componentData={componentData} headingId={headingId}/>

                    <pre className='item-description manage-your-business' >
                      <RichTextRenderer content={heroCardRightContent.fields.richText} />
                    </pre>

                    <LinkComponentItem data={componentData} linkProp='heroCardRightBtnUrl' children={
                      fields => <Button className='btn-bpPrimary mt-3'>{fields.heroCardRightButtonLable}</Button>
                    } />
                    <div className='btn-wrapper'>
                      <LinkComponentItem className='check-eligibility' data={componentData} linkProp='heroCardRightLinkUrl' children={
                        fields => (
                          <>
                            <span className='d-inline-block '>{fields.heroCardRightLinkLable}</span>
                            <Image src='/assets/icons/arrow-primary-small.svg' width={17} height={21} loading='lazy' decoding='async' alt='Small arrow pointing right' className='d-inline-block ml-3' />
                          </>
                        )
                      } />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </>
  )
}

export default HeroCardComponent
