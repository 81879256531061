import { useEffect, useState } from 'react';

const useWebpSupport = () => {
  const [supportsWebp, setSupportsWebp] = useState(false);

  useEffect(() => {
    const canvas = document.createElement('canvas');
    if (
      canvas.getContext &&
      canvas.getContext('2d') &&
      canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0
    ) {
      setSupportsWebp(true);
    }
  }, []);

  return supportsWebp;
};

export default useWebpSupport;
