import React from 'react'
import FeatureComponentItem from './featureComponentItem'
import FeatureComponentBanner from './featureComponentBanner'
import TagIDProvider from '../../providers/TagIDProvider'
import './feature-component.scss'

const tagID = new TagIDProvider('title')

const FeatureComponentTitle = ({ title, id }) => (title ? <h2 id={id} className='feature-component-title'>{title}</h2> : <></>)

const FeatureComponent = ({ componentData, headingId }) => {
  if (!componentData.fields) {
    return <></>
  }
  const hasBanner = componentData.fields.featureComponentBanners && componentData.fields.featureComponentBanners.length
  const isCollapsedPadding = componentData.fields.reducePadding

  return (
    <div className={['feature-component__component', hasBanner ? ' has-banner' : '', isCollapsedPadding ? ' collapse-padding' : ''].join('')}>
      <div className='container'>
        <FeatureComponentTitle title={componentData.fields.featureComponentTitle} id={headingId} />
        <FeatureComponentBanner componentData={{
          banners: componentData.fields.featureComponentBanners,
          subTitle: componentData.fields.featureComponentSubtitle,
          body: componentData.fields.featureComponentBody,
          link: {
            url: componentData.fields.featureComponentDescriptionUrl,
            label: componentData.fields.featureComponentBodyUrlLabel
          }
        }} />
        {
          (componentData.fields.featureComponentItems || []).map((item, i) => (<FeatureComponentItem
            key={i}
            componentData={item}
            headingId={tagID.getAnIDFromName(componentData.componentType, item.sys.id)}
          />))
        }
      </div>
    </div>
  )
}

export default FeatureComponent
