import React from 'react'
import { Image } from 'react-bootstrap'
import LinkComponentItem from '../linkComponent/linkComponentItem'
import RichTextRenderer from '../richTextRenderer/richTextRenderer';
import useWebpSupport from '../../hooks/useWebPSupport';

const FeatureComponentItem = ({ componentData, headingId }) => {
  const isWebpSupport = useWebpSupport();
  
  const imgWidthHeight = '?w=280&h=298';

  const webp = isWebpSupport ? '&fm=webp&q=80' : '';

  if (!componentData.fields) {
    return <></>
  }
  const imgURL = componentData.fields.itemsImage ? componentData.fields.itemsImage.fields.file.url : ''
  const imgALT = componentData.fields.itemsImage ? componentData.fields.itemsImage.fields.title : ''

  return (
    <div className='feature-item'>
      <div className='item-inner'>
        <div className='icon'>
          <Image src={`${imgURL}${imgWidthHeight}${webp}`} width={140} height={150} loading='lazy' decoding='async' alt={imgALT} />
        </div>
        <div className='desc'>
          <h4 id={headingId} className='item-title mt-2'>{componentData.fields.itemTitle}</h4>
          <div className='item-description'>
            <RichTextRenderer content={componentData.fields.itemDescription} />
          </div>

          <LinkComponentItem data={componentData} linkProp='itemLinkUrl' className='find-out-more' children={
            fields => (
              <>
                <span className='d-inline-block'>{fields.itemLinkLabel}</span>
                <div className='d-inline-block ml-3'>
                  <Image src='/assets/icons/arrow-primary-small.svg' width={17} height={21} loading='lazy' decoding='async' alt='Small arrow pointing right' />
                </div>
              </>
            )
          } />
        </div>
      </div>
    </div>
  )
}

export default FeatureComponentItem
