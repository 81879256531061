import * as Types from './../constants/ActionTypes'
const initalState = {
  isReady: false
}

const dataProducer = (state = initalState, action) => {
  switch (action.type) {
    case Types.GET_DATA__BEGIN:
      return {
        ...action.payload
      };
    case Types.GET_DATA__SUCCESS:
      return {
        ...action.payload
      };
    default:
      return state
  }
}

export default dataProducer