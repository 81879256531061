import React, { useState, useMemo } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import CurrencyInput from './currencyInput'
import FuelDetailItem from './fuelDetailItem'
import LinkComponentItem from '../linkComponent/linkComponentItem'
import { calcBenefitPerYear } from '../../providers/FuelCalcProvider'
import './fuel-component.scss'

const FuelCalcResult = ({ componentData, resultSavedPerYear, resultEarnPerYear }) => {
  return (
    <Row className='mt-4'>
      <Col>
        <Card>
          <Card.Body>
            <Row className='grid-divider' style={{ padding: '20px 0' }}>
              <Col xs={12} md={6}>
                <h2 className='you-can-save'>YOU CAN SAVE*</h2>
                <h6 className={'amount'}>{resultSavedPerYear}</h6>
                <h6 className='per-year'>per year</h6>
              </Col>
              <Col xs={12} md={6}>
                <h2 className='and-earn'>AND EARN</h2>
                <h6 className='amount'>{resultEarnPerYear}</h6>
                <h6 className='per-year'>Qantas Points per year</h6>
              </Col>
            </Row>
            <div className='btn-wraper' style={{ textAlign: 'center' }}>
              <LinkComponentItem data={componentData} linkProp='switchButtonUrl' children={
                () => <Button className='btn-bpDarkOutline'>{componentData.switchButtonLabel}</Button>
              } />
            </div>
            <Row>
              <Col>
                <div style={{ textAlign: 'center' }}>
                  <p className='based-on-current-fu d-inline-block'>{componentData.tcDescription}</p>
                  <LinkComponentItem data={componentData} linkProp='tcLinkUrl' className='see-terms-conditio d-inline-block'>{componentData.tcLink}</LinkComponentItem>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

const FuelCalcForm = ({ componentData }) => {
  return (
    <Form>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group controlId='avgWeeklySpend'>
            <Form.Label className='average-weekly-spend'>{componentData.fields.inputLabel1}</Form.Label>
            <CurrencyInput placeholder='0' type='text' className='form-control' value={componentData.avgPerVehicle} onChange={e => componentData.setAvgPerVehicle(e.target.value)} />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId='numOfVehicles'>
            <Form.Label className='number-of-vehicles-i'>{componentData.fields.inputLabel2}</Form.Label>
            <CurrencyInput placeholder='0' type='text' className='form-control' value={componentData.numberOfVehicle} onChange={e => componentData.setNumOfVehicle(e.target.value)} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className='number-of-vehicles-i'>{componentData.fields.checkboxLabel}</Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <FuelCalcResult componentData={componentData.fields} resultSavedPerYear={componentData.resultSavedPerYear} resultEarnPerYear={componentData.resultEarnPerYear} />
      <div id="calculator-results" style={{display: componentData.showCalculatorResults ? 'block' : 'none' }}></div>
    </Form>
  )
}

const FuelComponent = ({ componentData, headingId }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [resultSavedPerYear, setResultSavedPerYear] = useState(0)
  const [resultEarnPerYear, setResultEarnPerYear] = useState(0)
  const [avgPerVehicle, setAvgPerVehicle] = useState()
  const [numOfVehicle, setNumOfVehicle] = useState()
  const [selectedData, setSelectedData] = useState()
  const [showCalculatorResults, setShowResult] = useState(true)

  useMemo(() => {
    const calcSelectedBenefit = index => {
      setSelectedData((componentData.fields.fuelDetail || [])[index])
      if (selectedData) {
        const benefit = calcBenefitPerYear(avgPerVehicle, numOfVehicle, selectedData.fields.fuelDiscountInCent, selectedData.fields.qantasPoints)
        setResultSavedPerYear(benefit.savedPerYear)
        setResultEarnPerYear(benefit.earnPerYear)
        setShowResult(benefit.showCalculatorResults)
      }
    }
    calcSelectedBenefit(selectedIndex)
  }, [selectedIndex, avgPerVehicle, numOfVehicle, selectedData, componentData.fields.fuelDetail])


  if (!componentData.fields) return
  const handleFuelItemCLick = index => setSelectedIndex(index)

  return (
    <div className='fuel-component__component'>
      <div className='fuel-title'>
        <div className='container'>
          <h2 id={headingId} className='title-text'>{componentData.fields.titleText}</h2>
        </div>
      </div>
      <div className='fuel-content'>
        <div className='container'>
          <div>
            <div className='fuel-tab-list'>
              <div className='tab-inner'>
                {
                  (componentData.fields.fuelDetail || []).map((item, i) => (
                    <FuelDetailItem key={i} selectedIndex={selectedIndex} index={i} componentData={item} onClickHandle={handleFuelItemCLick} />
                  ))
                }
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-xs-12 col-md-10'>
              <div className='fuel-from'>
                <FuelCalcForm componentData={{
                  resultSavedPerYear,
                  resultEarnPerYear,
                  avgPerVehicle, setAvgPerVehicle,
                  numOfVehicle, setNumOfVehicle,
                  showCalculatorResults, setShowResult,
                  fields: componentData.fields,
                }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FuelComponent
