import React from 'react'
import { connect } from 'react-redux'
import { mapUrlPathWithData, mapIdWithData } from '../../providers/DataProvider'
import Page404 from './page404'

import TagIDProvider from '../../providers/TagIDProvider'
import PageMeta from './meta';

import CookieBanner from '../cookieBanner/cookieBanner'

const ConnectedPage = ({ id, path, data }) => {
  if (!data.isReady) {
    return <></>
  }
  if(path.substr(-1) === '/') {
    path = path.substr(0, path.length - 1);
  }
  
  const pageData = id ? mapIdWithData(id, data.data) : mapUrlPathWithData(path, data.data)
  if (!pageData || !pageData.fields) {
    return <Page404 />
  }
  const tagID = new TagIDProvider()
  const Components = pageData.fields.pageComponents.map(component => {
    if (!component.componentType) {
      console.error('componentType', component)
      return {
        component: () => <></>
      }
    }

    return {
      component: require(`../${component.componentType}/${component.componentType}`).default,
      componentData: component,
      headingId: tagID.getAnIDFromName(component.componentType, component.sys.id)
    }
  })

  return (
    <>
      <PageMeta pageData={pageData} />

      <div className={pageData.classes}>
        {
          Components.map((C, index) => <C.component componentData={C.componentData} headingId={C.headingId} key={index} />)
        }
      </div>

      <CookieBanner />
    </>
  )
}

const mapStateToProps = store => store

export default connect(mapStateToProps, null)(ConnectedPage)
