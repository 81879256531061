export const API_DATA_URL = 'https://{env}.contentful.com/spaces/{space}/entries?access_token={token}&content_type=page&include=10'

export const API_MENU_URL = 'https://cdn.contentful.com/spaces/{space}/entries?access_token={token}&content_type=linkComponent&include=10&query=menu'

export const PREVIEW_ROOT_URL = '/page-preview'

export const MAP_NAVIGATION_NAME = 'Navigation Menu'

export const MAP_MENU_LINK_NAME = 'Hamburger menu links'

export const FIXED_HOMEPAGE_ID = '6HF1DdwghJKWOlfzbcK4MP'